<template>
    <div>
        <slot></slot>
    </div>
</template>

<script>
export default {
}
</script>

<style scoped>
div {
    width: 100vw;
    height: 100vh;
    padding: 0 100px;
    padding-top: 30px;
    border-bottom: 1px solid #d3e7ff;
}
</style>