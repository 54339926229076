<template lang="html">
    <transition name="modal" @keyup.enter="add">
        <div id="modal-mask" class="modal-m" @click.self="close">
            <div class="modal-wr">
                <div class="modal-container">
                    <button type="button" class="close" @click="close"  data-dismiss="modal">&times;</button>

                    <div class="modal-body small">
                        <p class="header">
                            Консултация по {{ order_type }}
                        </p>
                        <div>
                            <div class="form-group">
                                <label for="" class="col-sm-4 col-form-label col-form-label-sm">Имя:</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control form-control-sm" :value="name" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="" class="col-sm-4 col-form-label col-form-label-sm">Телефон:</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control form-control-sm" :value="phone" />
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="" class="col-sm-4 col-form-label col-form-label-sm">Email:</label>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control form-control-sm" :value="email" />
                                </div>
                            </div>
                            
                        </div>
                        <div class="d-flex justify-content-center">
                            <button class="btn" @click="send">Отправить</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

export default {
    props: ['soft'],
    data(){
        return{
            name: '',
            phone: '',
            email: '',
            type: '',

        }
    },
    methods: {
        close(){
            this.$emit('close');
        },
        send(){

        }
    },
    computed: {
        order_type(){
            switch(this.soft.order_type){
                case 'web': return 'Web - Разработке'
                case 'cloud': return 'Облачному офису'
                default: return 'Продукт'
            }
        }
    },
    mounted(){
    }
}
</script>

<style lang="css" scoped>
.modal-m {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
background-color: rgba(0, 0, 0, .5);
display: flex;
justify-content: center;
align-items: flex-start;
transition: opacity .3s ease;
}

.modal-wr {
    margin-top: 180px;
    margin-bottom: 110px;
}

.modal-container {
width: 500px;
overflow: hidden;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 6px;
box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
transition: all .3s ease;
font-family: Helvetica, Arial, sans-serif;
}


.modal-body {
    margin: 20px 0;
}

.modal-default-button {
float: right;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}

.sticky-bottom {
    position: relative;
    bottom: 0px;
}

.header {
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>
