<template>
    <div class="block">
        <div class="row">
            <div class="col-sm-3">
            </div>
            <div class="col-sm-6 text-center row">
                <div class="col-sm-12 header">
                    Защищаем данные компании
                </div>
            </div>
            <div class="col-sm-3 text-right">
            </div>
        </div>
        <div class="row content d-flex justify-content-center">
            <div class="product-block d-flex justify-content-between align-items-start">
                <div class="text-header">
                    Современные дата-центры
                    <div class="text-block">
                        Сервера нашей компании располагаются в лучших дата-центрах РФ. Все серверные площадки проходят по стандарту Tier 3. 
                    </div>
                </div>

                <div class="text-header">
                    Производительное оборудование
                    <div class="text-block">
                        При выборе оборудование мы используем современные комплектующие. Это позволяет обрабатывать большой объем данных.
                    </div>
                </div>
            </div>
            <!-- <div class="text-center shield">
                <img src="../assets/security1.png">
            </div> -->
            <div class="product-block d-flex justify-content-between align-items-end">
                <div class="text-header">
                    Шифрование данных
                    <div class="text-block">
                        Все способы подключения и данные шифруются. Вы можете не беспокоится об утечке данных.
                    </div>
                </div>
                
                <div class="text-header">
                    Безопасные сети
                    <div class="text-block">
                        Все сети нашей компании защищены Firewall. А распределение нагрузки позволяет избежать нехватки канала связи.
                    </div>
                </div>
            </div>
        </div>
        <div  class="arrow d-flex justify-content-center align-items-end">
            <img src="../assets/Arrow_down.svg" />
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.header {
    font-size: 40px;
}

.content {
    margin-top: 80px;
    background: url('../assets/security1.png') no-repeat;
    background-size: 660px;
    background-position: center;
}

.shield {
    width: 394px;
    padding: 100px 0;
    left: -10px;
    margin: 0 40px;
}

.shield img {
    width: 660px;
}

.product-block {
    width: 85%;
    height: calc((100vh - 30px - 60px - 240px)/2);
}

/* .product-block>div {
    margin: 10px 0;
    border-radius: 8px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 3px 4px rgba(130, 187, 255, 0.3);
    -moz-box-shadow: 0px 0px 3px 4px rgba(130, 187, 255, 0.3);
    box-shadow: 0px 0px 3px 4px rgba(130, 187, 255, 0.3);
    cursor: pointer;
}

.product-block>div:hover {
    -webkit-box-shadow: 0px 0px 3px 4px rgb(44, 118, 197, 0.3);
    -moz-box-shadow: 0px 0px 3px 4px rgb(44, 118, 197, 0.3);
    box-shadow: 0px 0px 3px 4px rgb(44, 118, 197, 0.3);
} */

.text-header {
    width: 465px;
    height: 120px;
    font-size: 20px;
    text-align: center;
}

.text-block {
    margin-top: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
}

.btn {
    border: 3px solid #82BBFF;
    border-radius: 8px;
    padding: 6px 30px;
}

.arrow {
    height: 150px;
}
</style>