import { render, staticRenderFns } from "./OrderModal.vue?vue&type=template&id=3c7e1282&scoped=true&lang=html"
import script from "./OrderModal.vue?vue&type=script&lang=js"
export * from "./OrderModal.vue?vue&type=script&lang=js"
import style0 from "./OrderModal.vue?vue&type=style&index=0&id=3c7e1282&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c7e1282",
  null
  
)

export default component.exports