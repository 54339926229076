<template>
    <div class="block">
        <div class="row">
            <div class="col-sm-3">
            </div>
            <div class="col-sm-6 text-center row">
                <div class="col-sm-12 header">
                    Ускоряем решение повседневных задач
                </div>
            </div>
            <div class="col-sm-3 text-right">
            </div>
        </div>
        <div class="row d-flex justify-content-center content">
            <div class="product-block d-flex flex-column justify-content-start align-items-center">
                <div class="img">
                    <img src="../assets/web-dev.png">
                </div>
                <div class="text-header">
                    Благодаря web - разработке вы получите:
                </div>
                <div class="text-block">
                    Уменьшение количества документооборота
                </div>
                <div>
                </div>
                <div class="text-block">
                    Онлайн контроль ипсолнения задач
                </div>
                <div>
                </div>
                <div class="text-block">
                    Автоматизация рутинных задач
                </div>
                <div>
                </div>
                <div class="text-block">
                    Контроль финансового результата компании
                </div>
                <div>
                </div>
                <div class="text-more">
                    Еще много полезных функций
                </div>
                <div class="btn">Подробнее</div>
            </div>
            <div class="center-block">
            </div>
            <div class="product-block d-flex flex-column justify-content-start align-items-center">
                <div class="img">
                    <img src="../assets/cloud2.png">
                </div>
                <div class="text-header">
                    Используя облачные сервера вы получите:
                </div>
                <div class="text-block">
                    Возможность работы из любой точки мира
                </div>
                <div>
                </div>
                <div class="text-block">
                    Быстрый доступ к ресурсам компании
                </div>
                <div>
                </div>
                <div class="text-block">
                    Легкий обмен данными внутри компании
                </div>
                <div>
                </div>
                <div class="text-block">
                    Быстрый ввод новых сотрудников
                </div>
                <div>
                </div>
                <div class="text-more">
                    Еще много полезных функций
                </div>
                <div class="btn">Подробнее</div>
            </div>
        </div>
        <div  class="arrow d-flex justify-content-center align-items-end">
            <img src="../assets/Arrow_down.svg" />
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>



.header {
    font-size: 40px;
    /* color: #82BBFF; */
}

.content {
    margin-top: 140px;
}

.center-block {
    width: 200px;
}


.product-block {
    width: 440px;
}

.product-block .img {
    height: 265px;
}

.product-block .img img {
    width: 300px;
}

.text-header {
    margin-top: 32px;
    margin-bottom: 14px;
    font-size: 20px;
}

.text-block {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}


.text-more {
    margin-top: 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.btn {
    margin-top: 30px;
}


.arrow {
    height: 135px;
}

</style>