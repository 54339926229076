<template>
    <div class="block">
        <div class="row">
            <div class="col-sm-3">
            </div>
            <div class="col-sm-6 text-center row">
                <div class="col-sm-12 header">
                    Заказать
                </div>
            </div>
            <div class="col-sm-3 text-right">
            </div>
        </div>
        <div class="product-block d-flex flex-column justify-content-center  align-items-center">
            <div class="text-body d-flex">
                    <div><img src="../assets/classicphone.svg" />Позвонить нам: +7 (989) 259-13-93</div>
                    <div><img src="../assets/socialemailcircularbutton.svg" />Отправить письмо: sales@cloud-office.net</div>
                </div>
                
                <div class="text-header">
                    Оставить заявку на обратный звонок:
                </div>
                <div class="form">
                    <label for="name" class="col-sm-4 col-form-label col-form-label-sm">Имя:</label>
                    <div class="col-sm-12">
                        <input type="text" class="form-control  form-control-sm" id="name" placeholder="Имя"
                        v-model="request.name"
                        />
                    </div>
                    <label for="phone" class="col-sm-4 col-form-label col-form-label-sm">Телефон:</label>
                    <div class="col-sm-12">
                        <input type="text" class="form-control  form-control-sm" id="phone" placeholder="Телефон"
                        v-model="request.phone"
                        />
                    </div>
                    <label for="email" class="col-sm-4 col-form-label col-form-label-sm">Email:</label>
                    <div class="col-sm-12">
                        <input type="text" class="form-control  form-control-sm" id="email" placeholder="Email"
                        v-model="request.email"
                        />
                    </div>
                </div>
                <div class="btn">Отправить</div>
        </div>
        <div class="footer d-flex justify-content-center align-items-center">
            <div class="link text-right">
                <p>Работа в Cloud-Office</p>
                <p>Empty</p>
                <p>Empty</p>
            </div>
            <div class="text-center">
                <img src="../assets/white-logo.svg"/>
            </div>
            <div class="link text-left">
                <p>Адрес: г. Новороссийск, ул. Новороссийской республики, д. 58</p>
                <p>Телефон: +7 (989) 259-13-93</p>
                <p>Email: info@cloud-office.net</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            request: {
                name: '',
                phone: '',
                email: ''
            }
        }
    }
}
</script>

<style scoped>

.block {
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.header {
    font-size: 40px;
    color: #82BBFF;
}

.content {
    margin-top: 140px;
}

.shield {
    width: 394px;
    padding: 100px 0;
    left: -10px;
    margin: 0 40px;
}

.product-block {
    margin-top: 40px;
    flex: 1 0 auto;
}

/* .product-block div {
    margin: 10px 0;
} */

.text-header {
    font-size: 20px;
    text-align: center;
    margin-top: 40px;
}

.text-body {
    text-align: center;
    font-size: 14px;
}

.text-body div{
    margin: 0 20px;
}

.text-body img{
    margin: 0 20px;
}

.btn {
    border: 3px solid #82BBFF;
    border-radius: 8px;
    padding: 6px 30px;
    margin: 40px;
}

.form {
    width: 390px;
}

.form label {
    margin-top: 10px;
}

.footer {
    width: 100vw;
    height: 110px;
    background: #82BBFF;
    flex: 0 0 auto;
    position: relative;
    left: -100px;
}

.footer p {
    font-size: 10px;
    color: #fff;
    margin: 0;
    padding: 0;

}

.footer .text-center {
    width: 200px;
}

.footer .link {
    width: 500px;
    cursor: pointer;
}

.link p:hover {
    color: rgb(114, 134, 155);
}

</style>