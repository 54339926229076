import Vue from 'vue'
import App from './App.vue'

import VCalendar from 'v-calendar';

// import VueDatePicker from '@vuepic/vue-datepicker';
// import '@vuepic/vue-datepicker/dist/main.css'

Vue.config.productionTip = false;

new Vue({
  render: h => h(App)
}).$mount('#app');

Vue.use(VCalendar, {
  componentPrefix: 'v'
});


// const app = createApp(App);

// app.component('VueDatePicker', VueDatePicker);
// app.$mount('#app');
