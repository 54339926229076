<template>
    <div class="block">
        <div class="row top-block">
            <div class="col-sm-2 logo">
                <img src="../assets/Logo.svg" />
            </div>
            <div class="col-sm-4 menu">
                <span @click="soft.goto('/')">Главная</span>
                <span @click="soft.goto('/products')">Услуги</span>
                <span @click="soft.goto('/contacts')">Контакты</span>
            </div>
            <div class="col-sm-3 phone">
                <img  src="../assets/classicphone.svg" />
                <span>8 (989) 259-13-93</span>
            </div>
            <div class="col-sm-3 login">
                <img class="pointer" src="../assets/Login.svg" @click="soft.showModal('login')"/>
            </div>
        </div>
        <div class="banner d-flex justify-content-center align-items-center">
            <div class="left d-flex flex-column justify-content-start align-items-center">
                <div class="header">Облачные решения для бизнеса</div>
                <div class="text">
                    <div>Разработка CRM и ERP систем под бизнес процессы вашей компании. Для создания используются современные технологии и инструменты.</div> 
                    <div class="btn" @click="soft.showModal('web')">Оставить заявку</div>
                </div>
                
            </div>
            <div class="img d-flex justify-content-center align-items-center">
                <img src="../assets/develope.png" />
            </div>
        </div>
        <div  class="d-flex justify-content-center align-items-end">
            <img src="../assets/Arrow_down.svg" />
        </div>
    </div>
</template>

<script>
export default {
    props: ['soft']
}
</script>

<style scoped>
.top-block {
    width: 100vw;
}

.top-block .logo {
    text-align: right;
    cursor: pointer;
}

.top-block .logo img {
    width: 135px;
}

.top-block .menu {
    margin-top: 45px;
}

.top-block .menu span{
    margin-right: 25px;
    cursor: pointer;
}

.phone {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 29px;
}

.phone img {
    width: 40px;
    cursor: pointer;
}

.phone span {
    margin-top: 6px;
    margin-left: 16px;
    cursor: pointer;
}


.login {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.banner {
    height: calc(100vh - 140px - 85px);
}

.banner > div {
    height: calc(100vh - 140px - 85px);
}

.banner .left {
    padding-top: 100px;
    padding-right: 160px;
}

.banner .header {
    width: 555px;
    font-style: normal;
    font-weight: 400;
    font-size: 58px;
    line-height: 67px;
}

.banner .text {
    width: 400px;
    margin-top: 110px;

    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
}

.banner .img img {
    width: 440px;
}

.btn {
    margin-top: 45px;
}




</style>