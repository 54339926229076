<template>
    <div class="block">
        <div class="row">
            <div class="col-4">
                <button class="btn">Добавить услугу</button>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-8">
                <ul class="list-group hover pointer">
                    <li class="list-group-item header">
                        <div class="row">
                            <div class="col-8">Наименование услуги</div>
                            <div class="col-3">Стоимость</div>
                            <div class="col-1"></div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-8">Аренда облачного офиса</div>
                            <div class="col-3">30000 р./мес.</div>
                            <div class="col-1"><span class="oi oi-cog"></span></div>
                        </div>
                    </li>
                    <li class="list-group-item">
                        <div class="row">
                            <div class="col-8">CRM система</div>
                            <div class="col-3">5000 р./мес.</div>
                            <div class="col-1"><span class="oi oi-cog"></span></div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['soft'],
    data(){
        return{
        }
    },
    methods: {
    },
    computed: {
    },
    mounted(){
    }
}
</script>

<style scoped>
/* .header {
    border: 2px solid;
    border-color: #82BBFF;
} */
</style>
