<template lang="">
    <div class="block">
        <div class="row top-block">
            <div class="col-sm-2 logo" @click="soft.goto('/')">
                <img src="../assets/Logo.svg" />
                <p class="">Главная</p>
            </div>

            <div class="col-sm-7 menu">
                <div class="menu-item ml-3">
                    <img src="../assets/Cloud-server.svg" />
                    <p class="">Облачный офис</p>
                </div>
                <div class="menu-item ml-3">
                    <img src="../assets/Programming.svg" />
                    <p class="">Web-разработка</p>
                </div>
                <div class="menu-item ml-3">
                    <img src="../assets/Service.svg" />
                    <p class="">Прочие услуги</p>
                </div>
            </div>
                
            <div class="col-sm-3 login">
                <img class="pointer" src="../assets/Login.svg" @click="soft.showModal('login')"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['soft'],
    data(){
        return{

        }
    }
}
</script>

<style scoped>
.top-block {
    width: 100vw;
    height: 200px;
}

.top-block .logo {
    text-align: right;
    cursor: pointer;
}

.top-block .logo img {
    width: 135px;
}

.top-block .logo p {
    padding-right: 35px;
}

.menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.menu-item {
    width: 140px;
    text-align: center;
    cursor: pointer;
}

.menu-item:hover img {
    width: 120px;
}



.menu-item img {
    width: 80px;
}

.menu-item p {
    margin-top: 10px;
    text-align: center;
}

.login {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
</style>